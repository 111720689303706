import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnimatePresence, motion } from 'framer-motion'
import Chevron from '@elements/Chevron'
import { strings } from '../../static/strings'
import parse from 'html-react-parser'

const Section = tw.section`pt-spacingXl-xs xl:pt-spacingXl-master`
const SectionWrap = tw.div`relative flex flex-col`
const HeadlineWrap = tw.div`px-offset-xs max-w-screen-xl w-full mx-auto relative translate-y-spacingXs-master lg:translate-y-spacingS-master z-10`
const Headline = tw.p`w-full md:w-1/2 2xl:max-w-[750px] xl:w-2/3`
const Wrap = tw(motion.div)`max-w-screen-xl px-offset-xs mx-auto grid xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-10 w-full`
const Item = tw(motion.div)`relative`
const ImageWrap = tw(motion.div)`h-full w-full overflow-hidden pointer-events-none`
const Title = tw.p`font-futuraBold text-copyXl md:text-3xl tracking-1 md:tracking-3 pb-4`
const Content = tw.div`p-5 text-dark`
const Card = styled(motion.a)`
	${tw`relative block`}
	//hover effect
	.image-wrap:after {
		content: '';
		background-color: #003899;
		opacity: 0;
		transition: opacity ease-in-out 0.25s;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
	&:hover .image-wrap:after {
		opacity: 0.45;
	}
`
const Flex = tw.div`px-5 flex items-center gap-4`
const ShowMore = tw(
	motion.div
)`uppercase  mx-auto relative pt-2 mt-8 text-sm cursor-pointer text-primary tracking-1 md:tracking-2 before:absolute before:w-24 before:bottom-full before:left-0 before:h-px before:bg-light`

const WpAcfPressphotosoverviewBlock = ({ data, locale }) => {
	const { anchor } = data.attributes
	const lang = locale.substr(0, 2)
	const module = data.modulesPressphotosoverview

	const [showMoreState, setShowMoreState] = React.useState(8)

	return (
		<Section>
			<HeadlineWrap id={anchor !== '' ? anchor : 'pressphotosoverview'}>
				<Headline className='headline-h2'>{parse(strings[lang].pressPhotos)}</Headline>
			</HeadlineWrap>
			<SectionWrap className='py-16 lg:pt-28 lg:pb-48 bg-superLight'>
				{module?.teasers?.length > 0 && (
					<Wrap transition={{ staggerChildren: 0.5 }}>
						{module?.teasers.slice(0, showMoreState).map((item, index) => {
							return <PressCard key={`presse-card-${index}`} item={item} index={index} />
						})}
					</Wrap>
				)}
				{module?.teasers?.length > showMoreState && (
					<ShowMore onClick={() => setShowMoreState((current) => 8 + current)}>
						<Flex>
							<Chevron state={'down'} /> {strings[lang].showMore}
						</Flex>
					</ShowMore>
				)}
			</SectionWrap>
		</Section>
	)
}
const PressCard = ({ item, index }) => {
	return (
		<AnimatePresence key={index} exitBeforeEnter>
			<Item custom={index}>
				<Card href={item.dropboxUrl} target='_blank' rel='noopener noreferrer'>
					<ImageWrap>
						<motion.div className={`block h-full aspect-w-1 aspect-h-1`}>
							<div className='image-wrap'>
								{item.image?.localFile && <GatsbyImage className='w-full h-full !absolute' imgClassName='!absolute' image={getImage(item.image?.localFile)} alt={item.image?.altText} />}
							</div>
						</motion.div>
					</ImageWrap>
					<Content>
						<Title>{item.title}</Title>
					</Content>
				</Card>
			</Item>
		</AnimatePresence>
	)
}

export default WpAcfPressphotosoverviewBlock
